<template>
	<div class="container">
		
		<el-card style="margin-top: 15px;">
		
			<el-link type="primary" style="margin-bottom: 15px;" @click="handleBack">返回批量加好友>>></el-link>
		
			<el-table :data="list" :loading="tableLoading" style="width: 100%">
				<el-table-column prop="AddTime" label="操作时间" width="180">
				</el-table-column>
				<el-table-column prop="OperatorName" label="操作人" width="180">
				</el-table-column>
				<el-table-column prop="ImportChannelValue" label="导入渠道">
				</el-table-column>
				<el-table-column prop="AllImportCount" label="导入总数">
				</el-table-column>
				<el-table-column prop="SuccessImportCount" label="成功导入数量">
				</el-table-column>
				<el-table-column prop="ImportStateValue" label="状态">
				</el-table-column>
				<el-table-column prop="control" label="操作">
					<template slot-scope="scope">
						<div>
							<el-link type="primary" @click="importRecord(scope.row)" v-if="scope.row.ImportState === 1">下载失败数据</el-link>
							<div v-else>报表生成中...</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<div class="left">
				</div>
				<el-pagination :page-size="pageSize" :total="total" :current-page="pageIndex"
					:page-sizes="[5, 10, 15, 20, 30, 40]" @size-change="sizeChange" @current-change="currentChange"
					layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>

		</el-card>
		
	</div>
</template>

<script>
	
	import {
		qyWeixinAddFriendimportRecordlist
	} from '@/api/sv1.0.0.js';
	
	export default {
		components: {
		},
		data() {
			return {
				tableLoading: false,
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				list: []
			};
		},
		beforeMount() {
			this.getList();
		},
		methods: {
			handleBack(){
				this.$router.replace({
					name: 'batchAddFriend'
				})
			},
			// 列表
			async getList() {
				this.tableLoading = true;
				try {
					const res = await qyWeixinAddFriendimportRecordlist({
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize
					})
			
					if (res.IsSuccess) {
						this.list = res.Result.Results;
						this.total = res.Result.Total;
					}
				} catch (e) {} finally {
					this.tableLoading = false;
				}
			},
			sizeChange(value) {
				this.pageSize = value;
				this.getList();
			},
			currentChange(index) {
				this.pageIndex = index;
				this.getList()
			},
			importRecord(record){
				var url = location.origin + '/pc/qyWeixinAddFriend/importRecord/download?Id='+record.Id
				window.open(url)
			}
		}
	}
</script>

<style lang="less" scoped>
	.container {
		font-size: 14px;

		
		
		.pagination{
			display: flex;
			margin-top: 15px;
			justify-content: space-between;
			align-items: center;
		}
	}
</style>
